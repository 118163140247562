import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Hackerrank – Kangaroo exercise",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:52:37.000Z",
  "url": "/2018/02/hackerrank-kangaroo-exercise/",
  "categories": ["Exercises"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I got back to Hackerrank after quite a while and they got some new exercises so I decided to start clearing them from my desk. `}</p>
    <h5>{`Exercise Description`}</h5>
    <blockquote>
      <p parentName="blockquote">{`There are two kangaroos on an x-axis ready to jump in the positive direction (i.e, toward positive infinity). The first kangaroo starts at location X1 and moves at a rate of V1 meters per jump. The second kangaroo starts at location X2 and moves at a rate of V2 meters per jump. Given the starting locations and movement rates for each kangaroo, can you determine if they`}{`’`}{`ll ever land at the same location at the same time?`}</p>
    </blockquote>
    <h6>{`What I have done`}</h6>
    <p>{`This task is quite simple to solve but as I noticed a lot of developers solved it with a huge loop ~999999`}<br />{` and in the bottom line this approach solves the exercise, but this surely not the right solution for the long term as it will not solve the problem in big numbers and it surely is a waste of space and power.`}</p>
    <pre><code parentName="pre" {...{}}>{`If you write down your exercise on paper and try to think about it a bit before you start coding you will find a much better solution.



** You can read the exercise details in the bottom of the page.
`}</code></pre>
    <h6>{`Solution`}</h6>
    <pre><code parentName="pre" {...{}}>{`After you think about this question you get to under stand that you need to find the breakpoint of those 2 kangaroos.
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`X1 + V1*T = X2 + V2*T
`}</code></pre>
    <p>{`so after you do some simple math you get to this quotation:`}</p>
    <pre><code parentName="pre" {...{}}>{`T = (X1 - X2) / (V2 - V1)
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`so we have all the X1, V1, X2, V2 variables, so we have the T solution.



Now if T is negative the kangaroos will never meet, if T is not an integer so they will not meet as well.<br /> The only way the kangaroos will meet is when T is and unsigned integer.
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`    var res = (x1-x2)/(v2-v1);
    if ( ! Number.isInteger(res))
        return false;
    if (res &lt; 0)
        return false;
    return true;
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      